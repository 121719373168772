<template>
  <v-container style="background: rgba(255, 255, 255, .5); height: 100vh" class="pa-6" fluid>
    <backArrow style="margin-left: 14px;margin-top: 5px;position: initial"/>
    <v-row class="pt-6">
      <v-col class="pb-0" cols="12">
        <p style="margin-left: 14px;margin-top: 5px;" class="main-title">Principais Dúvidas</p>
      </v-col>
      <v-col class="pt-0" cols="12">
        <v-card class="search-card" >
          <v-layout style="height: 100%" align-center justify-center>
            <v-row>
              <v-col class="px-8" cols="12">
                <v-text-field
                    solo
                    flat
                    background-color="#F8F8FF"
                    color="#F8F8FF"
                    style="background: transparent;border-radius: 20px;  height: 53px"
                    placeholder="Qual a sua dúvida?"
                    v-model="search"
                    @input="filterArticles()"
                >
                  <img
                      @click="filterArticles()"
                      slot="append"
                      src="/img/icons/common/search-icon.svg"
                      style="width: 20px; height: 20px"
                  />
                </v-text-field>
              </v-col>
            </v-row>
          </v-layout>
        </v-card>
      </v-col>
      <v-col class="pt-0" col="12">
        <v-list v-if="articles.length > 0" style="background: rgba(255, 255, 255, 0)" three-line>
          <div
              v-for="item in articles"
              :key="item.id"
          >
            <v-list-item
                @click="detailFaq(item.id)"
            >
              <v-list-item-content>
                <p
                    style="font-size: 18px"
                    class="main-title"
                >
                  {{ item.question ? item.question : (item.summary ? item.summary : '-') }}
                </p>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon class="pl-10 pt-5">
                  <v-icon size="15" color="tertiary">
                    fas fa-chevron-right
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider/>
          </div>
        </v-list>
        <v-list v-else-if="loadingNick === false" style="background: rgba(255, 255, 255, 0)" three-line>
          <div @click="()=>($router.push({name: 'message'}))">
            <v-card
                class="balloonDetail"
                color="white"
                style="border-radius: 18px; border: #532E88 2px solid !important;"
            >
              <v-row class="pa-0 mb-4 mt-0 pt-0" style="height: 65%">
                <v-col style="color: #532E88" class="pt-0" align-self="center" cols="12">
                  <p class="px-4 pt-4 mb-0" align="center">Não encontrei resultado para sua pesquisa. Clique aqui para falar comigo!</p>
                </v-col>
              </v-row>
            </v-card>
            <v-row>
              <v-col cols="11">
                <img @click="()=>($router.push({name: 'message'}))" class="pt-8" style="display: block; margin-left: auto; margin-right: auto" id="img1" src="/img/nick/nick-umbrella.svg"/>
              </v-col>
            </v-row>
          </div>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
/* eslint-disable */
import { mapMutations } from "vuex";
import backArrow from '../../components/covid/back-arrow';
import { searchMainDoubts } from '../../services/odoo/KnowledgeService';

export default {
  components: { backArrow },
  mounted() {
    this.getFaqs();
  },
  data: () => ({
    articles: [],
    allArticles: [],
    search: '',
    loadingNick: true
  }),
  computed: {
    filterArticles() {
      if(this.search && this.allArticles.length) {
        let filtered = this.allArticles.filter((article) => {
          let query = this.search.toLowerCase();
          let summary = article.summary ? article.summary.toLowerCase() : '';
          let question = article.question ? article.question.toLowerCase() : '';

          return summary.indexOf(query) > -1 ||
              question.indexOf(query) > -1
        });

        this.articles = filtered;
      }else {
        this.articles = this.allArticles;
      }
    },
  },
  methods: {
    ...mapMutations(["showmsg", "loading"]),
    detailFaq(id) {
      this.$router.push({
        name: 'knowledge-article',
        query: { articleId: id }
      });
    },
    getFaqs() {
      this.loading(true);

      searchMainDoubts()
          .then((res) => {
            this.articles = res.data.records;
            this.allArticles = this.articles;
          })
          .catch(() =>
              this.showmsg({
                text: "Erro ao carregar principais dúvidas!",
                type: "error",
              })
          )
          .finally(() => {
            this.loading(false)
            this.loadingNick = false
          });
    },
  },
};
</script>
<style scoped>
.search-card {
  background: #F8F8FF !important;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1) !important;
  border-radius: 20px !important;
  height: 60px !important;
}

.balloonDetail:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid #532E88;
  border-right: 10px solid transparent;
  border-top: 11px solid #532E88;
  border-bottom: 10px solid transparent;
  right: 130px;
  bottom: -23px;
}

.balloonDetail:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid #fff;
  border-right: 10px solid transparent;
  border-top: 10px solid #fff;
  border-bottom: 10px solid transparent;
  right: 128.3px;
  bottom: -16.5px;
}

</style>